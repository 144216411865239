<template>
  <div class="newsListBg">
    <img src="images/news.png" />
    <div class="newsListWd">
      <div >
        <div class="newsBigMt" >
          <div class="newsTabFlex">
            <p v-for="(item,index) in newsList":key="index" @click="changeNews(index)" class="newsTabFlexp":class="newsListShow==index?'newsListActive':''">{{item.name}}</p>
          </div>
          <div class="newsContentImg" v-for="(item,index) in newscontentList" :key="index" @click="goNewsDetails(item)">
            <img :src="item.logo" />
            <div>
              <p class="newsTitles">{{item.title}}</p>
              <p>{{item.summary}}</p>
              <p>来源:{{item.createTime}}</p>
            </div>
          </div>
          <div class="newsPafeMr">
            <el-pagination
                class="text_center"
                background
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                layout="total,  prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>
<script>
  import {newsList} from "@/api";

  export default {
    data(){
      return{
        newsList:[
          {id:1,name:'企业资讯'},
          {id:2,name:'行业新闻'},
        ],
        newsListShow:0,
        newscontentList:[],
        currentPage: 1, //初始页
        pagesize: 10,
        total: 0,
      }
    },
    methods:{
    //  切换tab
      changeNews(index){
        this.newsListShow=index;
        this.getNewsList()
      },
    //  去新闻详情
      goNewsDetails(detail){
        let routeUrl = this.$router.resolve({
          path: "/newsDetail",//新页面地址
        });
        sessionStorage.setItem('newsDetail',JSON.stringify(detail))
        window.open(routeUrl.href);
      },
    //  获取新闻列表
      getNewsList(){
        let data={
          page:this.currentPage,
          size:this.pagesize
        }
        if(this.newsListShow==0){
          data['newsType']='1'
        }else{
          data['newsType']='2'
        }
        newsList(data).then(res => {
          this.newscontentList = res.data.records;
          this.total = JSON.parse(res.data.total) ;
        })
      },
      // 分页
      handleCurrentChange: function (currentPage) {
        this.currentPage = currentPage;
        this.getNewsList();
      },
    },
    mounted(){
      document.documentElement.scrollTop = 0;
      this.getNewsList()
    }
  }
</script>
<style scoped>
@import "../../../public/css/newsList.css";
</style>
